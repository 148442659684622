<template>
  <div>
    <v-btn-toggle style="background:none;" class="d-flex flex-wrap ml-2">
      <v-btn color="secondary" @click="selectCategory(false)">
        Alles
      </v-btn>
      <v-btn
        color="secondary"
        v-for="category of $store.state.settings.categories"
        :key="category"
        @click="selectCategory(category)"
      >
        {{ category }}
      </v-btn>
    </v-btn-toggle>
    <v-row class="my-0">
      <v-col cols="12" v-if="!selectedCategory">
        <h2 v-if="orders.length == 0">Er zijn geen orders</h2>
        <div
          v-for="(order, key) in orders"
          :key="order._id"
          :id="order._id"
          :name="order.name"
          :class="{ clearBoth: key === 7 || key === 14 || key === 21 }"
        >
          <table class="order">
            <tr>
              <td class="statusBar"></td>
              <th>
                <a>{{ order.name }}</a>
              </th>
              <td>
                <v-icon :color="orderStatuses[order.status].color">
                  {{ orderStatuses[order.status].icon }}</v-icon
                >
              </td>
            </tr>
            <tr
              v-for="item in order.items"
              :key="item._id"
              v-show="item.status < 2"
            >
              <td class="amount">
                <a>
                  {{ item.amount }}
                  <span class="qty">{{ item.unit }}</span>
                </a>
              </td>
              <td class="product">
                <a>{{ item.name }}</a>
              </td>
              <td>
                <v-icon :color="itemStatuses[item.status].color">{{
                  itemStatuses[item.status].icon
                }}</v-icon>
              </td>
            </tr>
          </table>
        </div>
      </v-col>
      <v-col cols="12" v-else>
        <div v-for="(children, property) in categories" :key="property">
          <table class="group" v-if="children.items.length">
            <tr>
              <th>{{ property }}</th>
            </tr>
            <tr v-for="item of children.items" :key="item._id">
              <td>
                <a>{{ item.client }}</a>
              </td>
              <td>
                <a>{{ item.amount }}{{ item.unit }}</a>
              </td>
              <td>
                <v-icon :color="itemStatuses[item.status].color">{{
                  itemStatuses[item.status].icon
                }}</v-icon>
              </td>
            </tr>
            <tr>
              <td>Totaal:</td>
              <td></td>
              <td>{{ children.total }}</td>
            </tr>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "tv",
  data() {
    return {
      interval: null,
      selectedCategory: false,
      itemStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "In afwachting"
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling"
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Voltooid"
        },
        {
          status: 3,
          icon: "mdi-checkbox-marked-circle",
          color: "orange",
          text: "Geselecteerd"
        },
        {
          status: 4,
          icon: "mdi-checkbox-marked-circle",
          color: "light-green",
          text: "Label afgedrukt"
        }
      ],
      orderStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "Nieuw",
          length: 0
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling",
          length: 0
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Voltooid",
          length: 0
        },
        {
          status: 3,
          icon: "mdi-checkbox-marked-circle",
          color: "black",
          text: "Gefactureerd",
          length: 0
        }
      ]
    }
  },
  methods: {
    autoScroll() {
      let top = 300
      this.interval = setInterval(function() {
        window.scrollBy({ top: top, behavior: "smooth" })
        if (
          window.innerHeight + window.scrollY >=
          document.documentElement.offsetHeight
        ) {
          top = -300
        }
        if (window.scrollY == 0) {
          top = 300
        }
      }, 3000)
    },
    selectCategory(cat) {
      this.categories2 = []
      this.categories2.length = 0
      this.selectedCategory = cat
    }
  },
  computed: {
    orders() {
      return this.$store.state.orders
    },
    categories() {
      let orders = this.$store.state.orders
      let categories = {}
      let items = []
      for (let order of orders) {
        for (let item of order.items) {
          item.client = order.name
          item.order_id = order._id
          item.orderNumber = order.orderNumber
          items.push(item)
        }
      }
      items = items.filter(item => item.category == this.selectedCategory)
      items = items.sort((a, b) => a.sort - b.sort)
      for (let item of items) {
        let cat = item.category
        categories = categories || {}
        categories[cat] = categories[cat] || {}
        categories[cat][item.name] = categories[cat][item.name] || {}
        categories[cat][item.name].items =
          categories[cat][item.name].items || []
        categories[cat][item.name].total = categories[cat][item.name].total || 0
        if (item.status < 2) {
          categories[cat][item.name].total =
            (categories[cat][item.name].total * 100 +
              parseFloat(item.amount) * 100) /
            100
          categories[cat][item.name].items.push(item)
        } //TODO: add totalWeight
      }
      return categories[this.selectedCategory]
    }
  },
  mounted() {
    this.autoScroll()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.clearBoth {
  clear: both;
}
.order,
.group {
  text-align: left;
  text-shadow: none;
  background: ghostwhite;
  color: black;
  border: 5px solid rgb(226, 226, 226);
  margin: 10px;
  float: left;
  width: 225px;
  border-radius: 5px;
}
.order th,
.order th a {
  color: #c41e3a;
  font-weight: bold;
}
.amount {
  width: 40px;
  height: 25px;
}
.statusBar {
  width: 60px;
}
</style>